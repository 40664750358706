<template>
    <canvas id="canvas" ref="canvas" v-bind:width="canvasWidth" v-bind:height="canvasHeight"></canvas>
</template>

<script>
    import { store } from '../store.js'
    //import { gsap } from 'gsap';
    
    export default {
        name: 'PageCanvas',
        
        // Properties returned from data() become reactive state
        // and will be exposed on `this`.
        data() {
            return {
                store,
                canvasElement: null,
                ctx: null,
                canvasWidth: window.innerWidth,
                canvasHeight: window.innerHeight,
                canvasX: 0,
                canvasY: 0
            }
        },
        
        // Methods are functions that mutate state and trigger updates.
        // They can be bound as event listeners in templates.
        methods: {},
        
        // Lifecycle hooks are called at different stages
        // of a component's lifecycle.
        // This function will be called when the component is mounted.
        mounted() {
            this.canvasElement = this.$refs.canvas
            this.ctx = this.canvasElement.getContext('2d')
            this.canvasX = this.canvasWidth / 2;
            this.canvasY = this.canvasHeight / 2;            
        },
        
        unmounted(){}
        
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #canvas {
        position: fixed;
        top: 0;
        left: 0;
        /*width: 100%;
        height: 100lvh;*/
        z-index: 1;
        pointer-events: none;
        user-select: none;
    }
</style>