import { reactive } from 'vue'

export const store = reactive({
    introAnimationDone: false,
    animatedScroll: 0,
    homeScrollY: 0,
    casehandle: null,
    canvasElement: null,
    ctx: null,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    canvasX: 0,
    canvasY: 0,
    image: {
        img: null,
        left: null,
        top: null,
        width: null,
        height: null,
		newLeft: null,
		newTop: null,
		newWidth: null,
		newHeight: null		
    },
	appData: []
})