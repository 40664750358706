import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { store } from './store.js'

const app = createApp(App)

const data_url = 'https://blankcreative.info/wp-2023/wp-content/uploads/appdata/data.json'
fetch(
	data_url, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
	})
	.then(response => response.json())
	.then(data => {
		store.appData = data
	
		app.use(router)
		app.mount('#app')
	})
	.catch(err => console.error(err));
