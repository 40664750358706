import Lenis from "@studio-freight/lenis";
import { store } from '../store.js'

// by convention, composable function names start with "use"
export function useLenis() {
    
    // state encapsulated and managed by the composable
    let lenis;

    // a composable can update its managed state over time.    
    let raf = (time) => {        
        lenis.raf(time);
        requestAnimationFrame(raf);
    }
    
    const initLenis = () => {
        
        //console.log('initLenis');
        
        lenis = new Lenis({
            content: document.documentElement,
            duration: 0.8,
            easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
            gestureOrientation: 'vertical',
            orientation: 'vertical',
            smoothWheel: true,
            infinite: false,
            wrapper: window
        });

        lenis.on('scroll', (e) => {
            store.animatedScroll = e.animatedScroll
        })

        requestAnimationFrame(raf);
        
    }
    
    // expose managed state as return value
    return { lenis, initLenis }
    
}