<template>
    <div>
        <Transition appear :css="false" @before-enter="onBeforeEnter" @enter="onEnter" mode="out-in">
            <a href="#" class="thumbnailimage" v-bind:data-fullimage="returnFullImageUrl" @click.prevent="goToFullImage" v-if="store.introAnimationDone && !fullImageShow">
                <picture>
                    <img v-bind:src="returnMediumImageUrl" loading="lazy" decoding="async" v-bind:width="returnMediumImageWidth" v-bind:height="returnMediumImageHeight">
                </picture>
            </a>
        </Transition>
        <Transition appear :css="false" @before-enter="onBeforeEnter" @enter="onEnter" mode="out-in">
            <a href="#" class="fullimage" @click.prevent="goToThumbnailImage" v-if="store.introAnimationDone && fullImageShow && fullImageSrc != null">
                <picture>
                    <img v-bind:src="returnFullImageUrl" loading="lazy" decoding="async" width="998" height="1334">
                </picture>
            </a>
        </Transition>
        <Transition appear :css="false" @before-enter="onBeforeEnterFadein" @enter="onEnterFadein">
            <div class="services" ref="services" v-if="store.introAnimationDone">
                <div class="type" v-html="returnServiceType"></div>
                <div class="for">for</div>
                <div class="client" v-html="returnServiceClient"></div>
            </div>
        </Transition>
        <Transition appear :css="false" @before-enter="onBeforeEnterSlidein" @enter="onEnterSlidein" @leave="onLeaveSlidein" mode="out-in">
            <div class="information" ref="information" v-if="store.introAnimationDone">
                <div class="details">
                    <div class="row">
                        <div class="label">Client</div>
                        <div class="text" v-html="returnClient"></div>
                    </div>
                    <div class="row">
                        <div class="label">Year</div>
                        <div class="text" v-html="returnYear"></div>
                    </div>
                    <div class="row">
                        <div class="label">Type</div>
                        <div class="text" v-html="returnType"></div>
                    </div>
                </div>
				<div class="credits">
                    <div class="row" v-for="row in this.store.appData.projects[this.store.appData.images[this.$route.params.handle].project].credits_list" :key="row">
                        <div class="label">{{ row.label }}</div>
                        <div class="text">{{ row.text }}</div>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
    import gsap from 'gsap'
    import { store } from '../store.js'

    export default {
        
        components: {},
        
        data() {
            return {
                store,
                fullImageSrc: null,
                fullImageShow: false,
                href: null,
				appData: [],
				handle: false
            }
        },        
        watch: {
            $route(to, from) {
                console.log('PageCase from: ', from.name)
                console.log('PageCase to: ', to.name)
            },
        },        
        created() {
			
			this.handle = this.$route.params.handle;
			
			const data_url = 'https://blankcreative.info/wp-2023/wp-content/uploads/appdata/data.json';
			// Simple GET request using fetch with set headers
			const headers = { "Content-Type": "application/json" };
			fetch(data_url, { headers })
				.then(response => response.json())
				.then(data => (this.appData = data));
			
		}, 
		computed: {
			returnMediumImageUrl(){
				const image_id = this.$route.params.handle;
				const image_url = this.store.appData.images[image_id].image_sizes.medium.url;
				return image_url;				
			},
			returnMediumImageWidth(){
				const image_id = this.$route.params.handle;
				const image_width = this.store.appData.images[image_id].image_sizes.medium.width;
				return image_width;				
			},
			returnMediumImageHeight(){
				const image_id = this.$route.params.handle;
				const image_height = this.store.appData.images[image_id].image_sizes.medium.height;
				return image_height;				
			},
			returnFullImageUrl(){
				const image_id = this.$route.params.handle;
				const image_url = this.store.appData.images[image_id].image_sizes.extralarge.url;
				return image_url;				
			},
			returnServiceType(){
				const image_id = this.$route.params.handle;
				const project_id = this.store.appData.images[image_id].project;
				const service_type = this.store.appData.projects[project_id].service_type;
				return service_type;				
			},
			returnServiceClient(){
				const image_id = this.$route.params.handle;
				const project_id = this.store.appData.images[image_id].project;
				const service_client = this.store.appData.projects[project_id].service_client;
				return service_client;				
			},
			returnClient(){
				const image_id = this.$route.params.handle;
				const project_id = this.store.appData.images[image_id].project;
				const client = this.store.appData.projects[project_id].client;
				return client;				
			},
			returnYear(){
				const image_id = this.$route.params.handle;
				const project_id = this.store.appData.images[image_id].project;
				const year = this.store.appData.projects[project_id].year;
				return year;				
			},
			returnType(){
				const image_id = this.$route.params.handle;
				const project_id = this.store.appData.images[image_id].project;
				const type = this.store.appData.projects[project_id].type;
				return type;				
			},
			returnCredits(){
				//const image_id = this.$route.params.handle;
				//const project_id = this.store.appData.images[image_id].project;
				const rows = `<div class="row"><div class="label">Art direction</div><div class="text">Homework</div></div>`;
				return rows;				
			}
		},
        methods: {
            clearCanvas(){
                store.ctx.clearRect(0, 0, store.windowWidth, store.windowHeight);
            },
            drawCanvas(){
                //console.log('drawCanvas', store.ctx, store.image.img, store.image.newLeft, store.image.newTop, store.image.newWidth, store.image.newHeight)
                store.ctx.clearRect(0, 0, store.windowWidth, store.windowHeight);
                store.ctx.drawImage(store.image.img, store.image.newLeft, store.image.newTop, store.image.newWidth, store.image.newHeight);
            },
            goToFullImage(element){
                const element_target = element.target;
                const link = element_target.closest('a');
                if( link ){
                    
                    store.caseHandle = this.$route.params.handle
                    const thumbnailimage = link.querySelector('img')
                    const fullimage = link.dataset.fullimage;
                    if( fullimage && element_target.nodeName == 'IMG' && window.innerWidth >= 768 ){
                        
                        link.style.pointerEvents = 'none';
                        
                        var downloadingImage = new Image();
                        downloadingImage.onload = () => {
                            
                            this.fullImageSrc = fullimage                            
                            
                            store.image.img = downloadingImage;
                            const link_rect = element_target.getBoundingClientRect();
                            store.image.left = Math.round(link_rect.left);
                            store.image.top = Math.round(link_rect.top);
                            store.image.width = Math.round(link_rect.width);
                            store.image.height = Math.round(link_rect.height);
                            store.image.newLeft = Math.round(link_rect.left);
                            store.image.newTop = Math.round(link_rect.top);
                            store.image.newWidth = Math.round(link_rect.width);
                            store.image.newHeight = Math.round(link_rect.height);
                            
                            this.drawCanvas();
                            link.style.visibility = 'hidden';
                            
                            gsap.to(store.image, {
                                duration: 0.75,
                                delay: 0.25,
                                ease: "expo",
                                newLeft: 0, 
                                newTop: 0, 
                                newWidth: window.innerWidth, 
                                newHeight: (store.image.height / store.image.width) * window.innerWidth, 
                                onUpdate: () => {
                                    this.drawCanvas()
                                },
                                onComplete: () => {
                                    this.fullImageShow = true
                                    setTimeout( () => {
                                        this.clearCanvas()
                                        link.style.pointerEvents = 'auto';
                                    }, 250)
                                }
                            });
                        };
                        downloadingImage.src = fullimage;
                        
                    } else {
                        
                        var thumbnailImage = new Image();
                        thumbnailImage.onload = () => {
                            
                            store.image.img = thumbnailImage;
                            const link_rect = thumbnailimage.getBoundingClientRect();
                            store.image.newLeft = Math.round(link_rect.left);
                            store.image.newTop = Math.round(link_rect.top);
                            store.image.newWidth = Math.round(link_rect.width);
                            store.image.newHeight = Math.round(link_rect.height);
                            
                            setTimeout( () => {
                                this.drawCanvas();
                                link.style.visibility = 'hidden';
                                this.$router.push('/')
                            }, 100)                            
                            
                        };
                        thumbnailImage.src = thumbnailimage.src;
                        
                    }                  
                }
            },
            goToThumbnailImage(element){
                const element_target = element.target;
                const link = element_target.closest('a');
                if( link ){
                    store.caseHandle = this.$route.params.handle
                    if( element_target.nodeName == 'IMG' ){
                        
                        link.style.pointerEvents = 'none';
                        
                        var downloadingImage = new Image();
                        downloadingImage.onload = () => {
                                
                            store.image.img = downloadingImage;
                            const link_rect = element_target.getBoundingClientRect();
                            store.image.newLeft = Math.round(link_rect.left);
                            store.image.newTop = Math.round(link_rect.top);
                            store.image.newWidth = Math.round(link_rect.width);
                            store.image.newHeight = Math.round(link_rect.height);

                            this.drawCanvas();
                            link.style.visibility = 'hidden';
                            
                            const storeImageWidth = store.image.width
                            const storeImageHeight = store.image.height

                            gsap.to(store.image, {
                                duration: 0.75,
                                delay: 0,
                                ease: "expo",
                                newLeft: 0, 
                                newTop: 0, 
                                newWidth: storeImageWidth, 
                                newHeight: storeImageHeight, 
                                onUpdate: () => {
                                    this.drawCanvas()
                                },
                                onComplete: () => {
                                    this.fullImageShow = false
                                    setTimeout( () => {
                                        this.clearCanvas()
                                        link.style.pointerEvents = 'auto';
                                    }, 250)
                                }
                            });
                            
                        };
                        downloadingImage.src = this.fullImageSrc;
                        
                    }                 
                }
            },
            onBeforeEnter(el) {
                gsap.set(el, {
                    opacity: 0
                })
            },
            onEnter(el, done) {
                gsap.to(el, {
                    duration: 0.25,
                    opacity: 1,
                    onComplete: done
                })
            },
            onLeave(el, done){
                gsap.to(el, {
                    duration: 0.25,
                    opacity: 0,
                    onComplete: done
                })
            },
            onBeforeEnterFadein(el) {
                gsap.set(el, {
                    opacity: 1,
                })
            },
            onEnterFadein(el, done) {
                gsap.to(el, {
                    duration: 0.25,
                    opacity: 1,
                    onComplete: done
                })
            },
            onLeaveFadein(el, done){
                gsap.to(el, {
                    duration: 0.25,
                    opacity: 0,
                    onComplete: done
                })
            },
            onBeforeEnterSlidein(el) {
                gsap.set(el, {
                    xPercent: 100
                })
            },
            onEnterSlidein(el, done) {
                gsap.to(el, {
                    duration: 0.5,
                    xPercent: 0,
                    ease: 'expo',
                    onComplete: done
                })
            },
            onLeaveSlidein(el, done){
                gsap.to(el, {
                    duration: 0.5,
                    opacity: 0,
                    onComplete: done
                })
            }
        },
        mounted() {
			
            store.homeScrollY = 0
            store.caseHandle = null
            document.documentElement.scrollTop = 0;
            
            this.canvasElement = document.getElementById('canvas')
            this.ctx = this.canvasElement.getContext('2d')
            this.canvasX = this.windowWidth / 2;
            this.canvasY = this.windowHeight / 2;
            
        },
        unmounted() {}
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    a.thumbnailimage {
        position: relative;
    }
    @media (min-width: 768px) {
        a.thumbnailimage {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            cursor: alias;
        }
    }
    a.thumbnailimage picture {
        position: relative;
    }
    @media (min-width: 768px) {
        a.thumbnailimage picture {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
    a.thumbnailimage picture img {
        position: relative;
        width: 100%;
        height: auto;
        display: block;
    }
    @media (min-width: 768px) {
        a.thumbnailimage picture img {
            position: absolute;
            top: 0;
            left: 0;
            width: auto;
            height: 100%;
            object-fit: contain;
            object-position: top left;
        }
    }
    @media (min-width: 768px) {
        a.thumbnailimage picture img:hover {
            cursor: zoom-in
        }
    }
    
    a.fullimage {
        position: relative;
        z-index: 1;
    }
    
    a.fullimage picture {
        position: relative;
    }
    
    a.fullimage picture img {
        position: relative;
        width: 100%;
        height: auto;
        display: block;
    }
    @media (min-width: 768px) {
        a.fullimage picture img:hover {
            cursor: zoom-out
        }
    }
    
    .services {
        position: relative;
        margin: 80px 20px 40px 20px;
        z-index: 9;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-row-gap: 40px;
        text-align: center;
        pointer-events: none;
        user-select: none;
        text-transform: uppercase;
    }
    @media (min-width: 768px) {
        .services {
            position: fixed;
            top: 180px;
            right: 0;
            width: 30%;
            margin: 0;
        }
    }
    .services > * {
        height: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    .information {
        position: relative;
        z-index: 9;
        pointer-events: none;
        user-select: none;
        text-transform: uppercase; 
        font-size: clamp(0.625rem, 0.625rem + 0vw, 0.625rem);
        line-height: 1.15;
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-row-gap: 40px;
        padding: 40px 20px;
    }
    @media (min-width: 768px) {
        .information {
            position: fixed;
            bottom: 0;
            right: 0;
            width: 30%;
            padding: 0 20px clamp(1.25rem, -2.0833rem + 6.9444vw, 6.25rem) clamp(1.25rem, -2.0833rem + 6.9444vw, 6.25rem);
        }
    }
    .information .details {
        display: grid;
        grid-auto-flow: row;
        grid-row-gap: 10px;
    }
    .information .details .row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1px;
    }
    .information .credits {
        display: grid;
        grid-auto-flow: row;
        grid-row-gap: 10px;
    }
    .information .credits .row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1px;
    }
    
    .fadein-enter-active,
    .fadein-leave-active {
        transition: opacity 500ms ease;
    }

    .fadein-enter-from,
    .fadein-leave-to {
        opacity: 0;
    }
    
    .slidein-enter-active,
    .slidein-leave-active {
        transition: transform 500ms cubic-bezier(0.87, 0, 0.13, 1);
    }

    .slidein-enter-from,
    .slidein-leave-to {
        transform: translate3d(100%, 0, 0);
    }
</style>