<template>
    <Header />
    <Services />
    <main>
        <router-view v-slot="{ Component, route }">
            <Transition name="fade" :duration="{ enter: 250, leave: 250 }" mode="out-in">
                <component :is="Component" :key="route.path" />
            </Transition>
        </router-view>
    </main>
    <Canvas />
    <Grid />
</template>

<script>
    import { store } from './store.js'
    
    import Header from './components/Header.vue'
    import Services from './components/Services.vue'
    import Canvas from './components/Canvas.vue'
    import Grid from './components/Grid.vue'
    
    import { useLenis } from './composables/lenis.js'
    import './composables/lenis.css'

    export default {
        name: 'App',
        data() {
            return {
                store
            }
        },
        components: {
            Header,
            Services,
            Canvas,
            Grid
        },
        mounted() {
			
            // lenis - original version
            //useLenis()
            // lenis - new version
            const { initLenis } = useLenis()
            initLenis()
            
            document.title = 'BLANK CREATIVE - Website'
            
            store.canvasElement = document.getElementById('canvas')
            store.ctx = store.canvasElement.getContext('2d')
            store.canvasX = this.windowWidth / 2;
            store.canvasY = this.windowHeight / 2;
            
        },
        beforeUnmount() {},
        methods: {}
    }

</script>

<style>
    
    :root {
        --color-purple: rgb(71, 77, 130);
        --color-grey: rgb(222, 222, 222);
        --color-darkgrey: rgb(46, 46, 46);
        --color-white: rgb(255, 255, 255);
        --scrolled_percentage: 0;
    }    
    
    @font-face {
        font-family: 'Helvetica Neue';
        src: url('./assets/webfonts/HelveticaNeueLTPro-Roman.woff2') format('woff2'),
            url('./assets/webfonts/HelveticaNeueLTPro-Roman.woff') format('woff');
        font-weight: 400;
        font-style: normal;
    }
    html {
        box-sizing: border-box;
    }
    *, *:before, *:after {
        box-sizing: inherit;
    }
    body {
        margin: 0;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;        
        font-size: clamp(0.75rem, 0.75rem + 0vw, 0.75rem);
        line-height: 1.15;
    }
    h1, h2, h3, h4, h5 {
        font-weight: 400;
        margin-top: 0;
    }
    p:first-child {
        margin-top: 0;
    }
    p:last-child {
        margin-bottom: 0;
    }
    /*
    p:not(:last-child) {
        margin-bottom: 20px;
    }
    @media (min-width: 768px) {
        p:not(:last-child) {
            margin-bottom: 40px;
        }
    }
    */
    
    /* main */
    main {
        position: relative;
        min-height: 100lvh;
        z-index: 2;
    }
    
    /* we will explain what these classes do next! */
    .v-enter-active,
    .v-leave-active {
        transition: opacity 250ms ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
    
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 250ms ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
    
</style>