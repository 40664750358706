<template>
    <div>
        <div class="video" ref="video" v-bind:class="{ show: store.introAnimationDone }" v-if="store.appData.video_file">
            <video v-bind:width="store.appData.video_file.width" v-bind:height="store.appData.video_file.height" @canplay="videoCanplay" v-bind:src="store.appData.video_file.url" autoplay muted loop playsinline></video>
            <img v-bind:src="store.appData.video_poster.url" v-bind:width="store.appData.video_poster.width" v-bind:height="store.appData.video_poster.height" class="poster">
        </div>
        <div class="services" ref="services" v-bind:class="{ show: store.introAnimationDone }">
            <div class="type" ref="type"></div>
            <div class="for">for</div>
            <div class="client" ref="client"></div>
        </div>
        <Transition appear :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave" mode="out-in">
            <div class="sections" ref="sections" v-if="store.introAnimationDone && store.appData.statements">
                <section class="statement" v-for="(item, index) in store.appData.statements" :key="item">
                    <div class="text" v-bind:data-index="index" v-html="item.description"></div>
                </section>
                <section class="contact">
                    <div class="text" v-html="store.appData.contact_description"></div>
                    <div class="form" v-if="store.appData.contact_inputfield_description && store.appData.contact_submit_label">
                        <form @submit.prevent="onSubmit" v-bind:data-email="store.appData.contact_email" v-bind:data-subject="store.appData.contact_subject">
                            <div class="input">
                                <input type="text" @focus="onFocus" @blur="onBlur">
                                <Transition name="fade" mode="out-in">
                                    <div class="placeholder" v-show="placeholderShow" v-html="store.appData.contact_inputfield_description"></div>
                                </Transition>
                            </div>
                            <button type="submit" v-html="store.appData.contact_submit_label"></button>
                        </form>
                    </div>
                </section>
            </div>
        </Transition>
    </div>
</template>

<script>
    import gsap from 'gsap'
    import { store } from '../store.js'

    export default {
        
        components: {},
        
        data() {
            return {
                store,
                placeholderShow: true,
                services: [],
                serviceIndex: 0,
                servicesMax: 100,
                servicesAnimationDone: false
            }
        },        
        created() {},
		computed: {},
        methods: {
			
			addServiceTypeClient(item){
				this.services.push({
					service_type: item.service_type,
					service_client: item.service_client
				})
			},			
			addServices(){
				const projects = this.store.appData.projects;
				Object.values(projects).forEach(this.addServiceTypeClient);
				
				/* unique multidimensional array */
				/* https://www.kirupa.com/javascript/removing_duplicate_arrays_from_array.htm */
				let stringArray = this.services.map(JSON.stringify);
				let uniqueStringArray = new Set(stringArray);
				this.services = Array.from(uniqueStringArray, JSON.parse);

				this.shuffleArray(this.services);
            
				if( window.innerWidth >= 768 ){
					this.initServicesAnimation();
				}
			},
			
            videoCanplay(){
                //console.log('videoCanplay', event.target)
                //const event_target = event.target;
                this.$refs.video.classList.add('videocanplay')
            },
            onBeforeEnter(el) {
                gsap.set(el, {
                    opacity: 0
                })
            },
            onEnter(el, done) {
                gsap.to(el, {
                    duration: 0.5,
                    opacity: 1,
                    onComplete: done
                })
            },
            onLeave(el, done){
                gsap.to(el, {
                    duration: 0.5,
                    opacity: 0,
                    onComplete: done
                })
            },
            onFocus(event) {
                const input_target = event.target;
                gsap.to(input_target, {
                    duration: 0.5,
                    opacity: 1
                })
                this.placeholderShow = false
            },
            onBlur(event) {
                const input_target = event.target;                
                if(input_target.value == ''){
                    gsap.to(input_target, {
                        duration: 0.5,
                        opacity: 0
                    })
                    this.placeholderShow = true
                }
            },
            onSubmit(event){
                const form_target = event.target;
                const input_field = form_target.querySelector('input');
                const email = form_target.dataset.email;
                const subject = form_target.dataset.subject;
                if(input_field.value != '' && email && subject && input_field.value.length < 40){
                    window.location.href = 'mailto:' + email + '?subject=' + subject + '&body=PHONE OR EMAIL: ' + input_field.value;
                }
            },
            
            setServicesAnimationDone() {
                console.log('setServicesAnimationDone');
                this.servicesAnimationDone = true
            },
            resetServicesAnimationDone() {
                this.servicesAnimationDone = false
                
                const servicesElement = this.$refs.services
                if(servicesElement){
                    gsap.to(servicesElement, {
                        duration: 0.5,
                        ease: 'expo',
                        opacity: 1
                    })
                }
                
            },
            shuffleArray(array) {
                for (let i = array.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    const temp = array[i];
                    array[i] = array[j];
                    array[j] = temp;
                }
            },
            addService(index){                
                
                console.log('addService', index);
                
                if(!this.$refs.type && !this.$refs.client){
                    return
                }
                
                const service = this.services[index]
                const service_type = service.service_type
                const service_client = service.service_client
                
                const typeElement = document.createElement('div')
                typeElement.style.position = 'absolute'
                typeElement.style.textAlign = 'center'
                typeElement.style.top = 0
                typeElement.style.right = 0
                typeElement.style.bottom = 0
                typeElement.style.left = 0
                typeElement.textContent = service_type
                this.$refs.type.appendChild(typeElement);
                
                gsap.set(typeElement, {
                    opacity: 0,
                    yPercent: 100
                })
                
                const clientElement = document.createElement('div')
                clientElement.style.position = 'absolute'
                clientElement.style.textAlign = 'center'
                clientElement.style.top = 0
                clientElement.style.right = 0
                clientElement.style.bottom = 0
                clientElement.style.left = 0
                clientElement.textContent = service_client
                this.$refs.client.appendChild(clientElement);
                
                gsap.set(clientElement, {
                    opacity: 0,
                    yPercent: 100
                })
                
            },
            playServicesAnimation(){
                console.log('playServicesAnimation')
                
                if(!this.$refs.type && !this.$refs.client){
                    return
                }
                    
                const existingTypeElement = this.$refs.type.querySelector('div:first-child')
                const existingClientElement = this.$refs.client.querySelector('div:first-child')
                const newTypeElement = this.$refs.type.querySelector('div:last-child')
                const newClientElement = this.$refs.client.querySelector('div:last-child')
                
                const tl = gsap.timeline({
                    onComplete: () => {
                        if(this.serviceIndex < this.servicesMax){
                            this.addNextServicesAnimation()
                        } else {
                            setTimeout(this.endServicesAnimation, 1000);
                        }
                    }
                });
                
                // slideout existing type and client
                if(existingTypeElement && existingClientElement){
                    
                    tl.to(existingTypeElement, {
                        duration: 0.25,
                        ease: "power3.out",
                        opacity: 0,
                        yPercent: -100,
                        onComplete: () => {
                            if( existingTypeElement.parentNode){
                                existingTypeElement.parentNode.removeChild(existingTypeElement)
                            }                            
                        }
                    })
                    tl.to(existingClientElement, {
                        duration: 0.25,
                        ease: "power3.out",
                        opacity: 0,
                        yPercent: -100,
                        onComplete: () => {
                            if( existingClientElement.parentNode ){
                                existingClientElement.parentNode.removeChild(existingClientElement)
                            }                            
                        }
                    }, '<0.2')
                }
                if(newTypeElement && newClientElement){
                    tl.to(newTypeElement, {
                        duration: 0.25,
                        ease: "power3.out",
                        opacity: 1,
                        yPercent: 0
                    }, '<-0.2')
                    tl.to(newClientElement, {
                        duration: 0.25,
                        ease: "power3.out",
                        opacity: 1,
                        yPercent: 0
                    }, '<0.2')
                }
                
            },
            addNextServicesAnimation(){
                console.log('addNextServicesAnimation', this.servicesAnimationDone)
                
                if( this.servicesAnimationDone == true ){
                    return
                }
                
                if( this.$refs.type && this.$refs.client ){
                    console.log(this.$refs.type.querySelectorAll('div').length, this.$refs.client.querySelectorAll('div').length);
                    
                    if( this.$refs.type.querySelectorAll('div').length <= 1 && this.$refs.client.querySelectorAll('div').length <= 1 ){
                        
                        if(this.serviceIndex < (this.services.length - 1)){
                            this.serviceIndex++;
                        } else {
                            this.serviceIndex = 0
                        }                

                        this.addService(this.serviceIndex)
                        setTimeout(this.playServicesAnimation, 2000);
                        
                    }
                }               
                
            },
            endServicesAnimation(){
                console.log('ServicesAnimation ended');
                
                this.setServicesAnimationDone()
                if( this.$refs.type && this.$refs.client ){
                    this.$refs.type.innerHTML = ''
                    this.$refs.client.innerHTML = ''
                }
                
                const servicesElement = this.$refs.services
                if(servicesElement){
                    gsap.to(servicesElement, {
                        duration: 0.5,
                        ease: 'expo',
                        opacity: 0,
                        onComplete: () => {}
                    })
                }
            },
            initServicesAnimation(){                
                console.log('initServicesAnimation')
                
                if(!this.$refs.type && !this.$refs.client){
                    return
                } else {
                    this.$refs.type.innerHTML = ''
                    this.$refs.client.innerHTML = ''
                }
                
                this.addService(this.serviceIndex)
                
                // slidein new type and client
                const existingTypeElement = this.$refs.type.querySelector('div:first-child')
                const existingClientElement = this.$refs.client.querySelector('div:first-child')
                
                const tl = gsap.timeline({
                    onComplete: () => {
                        this.addNextServicesAnimation()
                    }
                });
                
                if(existingTypeElement && existingClientElement){                    
                    tl.to(existingTypeElement, {
                        duration: 0.5,
                        ease: 'expo',
                        opacity: 1,
                        yPercent: 0
                    })
                    tl.to(existingClientElement, {
                        duration: 0.5,
                        ease: 'expo',
                        opacity: 1,
                        yPercent: 0
                    }, '<0.2')
                }
                
            },
            handleVisibility () {
                console.log('handleVisibility')
                
                if (document.visibilityState === 'hidden') {
                    // pause animation, video, etc
                    console.log('pause')
                    this.endServicesAnimation()
                    if(this.$refs.type && this.$refs.client){
                        this.$refs.type.innerHTML = ''
                        this.$refs.client.innerHTML = ''
                    }
                } else {
                    // continue operation
                    console.log('continue')
                    
                    const servicesElement = this.$refs.services
                    if(servicesElement){
                        gsap.set(servicesElement, {
                            opacity: 0
                        })
                    }
                    
                    if(this.$refs.type && this.$refs.client){
                        this.$refs.type.innerHTML = ''
                        this.$refs.client.innerHTML = ''
                    }
                    
                    this.servicesAnimationDone = false
                    this.initServicesAnimation()
                    
                    if(servicesElement){
                        gsap.to(servicesElement, {
                            duration: 0.5,
                            ease: 'expo',
                            opacity: 1,
                            onComplete: () => {}
                        })
                    }
                    
                }
            }        
        },
        mounted() {
			
			this.addServices()
            
            store.homeScrollY = 0
            store.caseHandle = null
            document.documentElement.scrollTop = 0;
            
            document.addEventListener('visibilitychange', this.handleVisibility, false)
			
        },
        beforeUnmount() {
            // the listener is on the document, not the component that gets destroyed
            //document.removeEventListener('visibilitychange', this.handleVisibility, false)
        },
        unmounted() {}
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 250ms ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
    
    .video {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
        user-select: none;
        z-index: 1;
        background-color: #000;
        opacity: 0;
    }
    @media (min-width: 768px) {
        .video {
            left: auto;
            right: 0;
            width: 70%;
        }
    }
    .video.show {
        opacity: 1;
        transition: opacity 1000ms ease;
    }
    .video:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
        user-select: none;
        z-index: 1;
        background-color: #242424;
        opacity: 0.25;
        mix-blend-mode: multiply;
    }
    
    .video video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    } 
    .video .poster {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .video.show .poster {
        opacity: 0;
        transition: opacity 500ms ease;
    } 
    
    .services {
        position: relative;
        margin: 0 20px;
        min-height: 100lvh;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-row-gap: 40px;
        text-align: center;
        pointer-events: none;
        user-select: none;
        text-transform: uppercase;
        display: none;
        opacity: 0;
    }
    @media (min-width: 768px) {
        .services {
            position: fixed;
            top: 0;
            left: 0;
            width: calc(30% - 40px);
            display: flex;
        }
    }
    .services.show {
        opacity: 1;
        transition: opacity 500ms ease 1000ms;
    }
    .services > div {
        position: relative;
		width: 100%;
        height: 15px;
        line-height: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
		text-align: center;
    }
    
    .sections {
        position: relative;
        z-index: 3;
    }
    @media (min-width: 768px) {
        .sections {
            margin-left: 30%;
        }
    }
    
    .sections section.statement {
        display: flex;
        justify-content: center;
        align-items: center;		
        margin: 10svh 0;
    }
	
	.sections section.statement:first-child {
		margin-top: 20svh;
	}
	@media (min-width: 768px) {
        .sections section.statement:first-child {
            margin-top: 10svh;
        }
    }
    .sections section.statement .text {
        position: relative;
        margin: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        pointer-events: none;
        user-select: none;
        line-height: 2.4;
        text-transform: uppercase;
        color: white;
        max-width: 40em;
    }
    @media (min-width: 768px) {
        .sections section.statement .text {
            line-height: 4.7;
        }
    }
    
    .sections section.statements {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sections section.statements .lines {
        position: relative;
        margin: 0 20px;
        min-height: 100lvh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-row-gap: 40px;
        text-align: center;
        pointer-events: none;
        user-select: none;
        text-transform: uppercase;
        color: white
    }    
    .sections section.statements .lines > * {
        position: relative;
        height: 15px;
        line-height: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    .sections section.services_types {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: 2.4;
        text-transform: uppercase;
        color: white;
    }
    @media (min-width: 768px) {
        .sections section.services_types {
            line-height: 4.7;
        }
    }
    
    .sections section.services_clients {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: 2.4;
        text-transform: uppercase;
        color: white;        
        margin-top: 200px;
    }
    @media (min-width: 768px) {
        .sections section.services_clients {
            margin-top: 400px;
            line-height: 4.7;
        }
    }
    
    .sections section.contact {
        min-height: 100lvh;
        display: grid;
        grid-auto-flow: row;
        align-items: center;
        grid-row-gap: 40px;
        text-align: center;
        line-height: 2.4;
        text-transform: uppercase;
        color: white;
    }
    @media (min-width: 768px) {
        .sections section.contact {
            grid-row-gap: 100px;
            line-height: 4.7;
        }
    }
    .sections section.contact .text {
        align-self: flex-end;
    }
    .sections section.contact .form {
        align-self: flex-start;
        padding: 0 20px;
    }
    .sections section.contact .form form {
        display: grid;
        grid-auto-flow: row;
        align-items: center;
        grid-row-gap: 20px;
        text-align: center;
        line-height: 2.4;
        text-transform: uppercase;
        color: white;
    }
    @media (min-width: 768px) {
        .sections section.contact .form form {
            line-height: 4.7;
        }
    }
    .sections section.contact .form form .input {
        justify-self: center;
        position: relative;
        width: 100%;
        max-width: 400px;
        border: 1px solid white;
        border-radius: 10px;
    }
    .sections section.contact .form form .input input {
        position: relative;
        width: 100%;
        padding: 30px 0;
        white-space: nowrap;
        display: inline-flex;
        border: none;
        border-radius: 0;
        background-color: transparent;
        outline: none;
        text-transform: uppercase;
        text-align: center;
        color: white;
        justify-self: center;
        appearance: none;
        font-size: clamp(0.75rem, 0.75rem + 0vw, 0.75rem);
    }
    .sections section.contact .form form .input input::placeholder {
        color: white;
        opacity: 1;
    }
    .sections section.contact .form form .input input:-ms-input-placeholder {
        color: white;
    }
    .sections section.contact .form form .input input::-ms-input-placeholder {
        color: white;
    }
    
    .sections section.contact .form form .input .placeholder {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        transform: translate3d(0, -50%, 0);
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        user-select: none;
    }
    .sections section.contact .form form button {
        position: relative;
        width: fit-content;
        padding: 5px 0;
        white-space: nowrap;
        display: inline-flex;
        border: none;
        background-color: transparent;
        outline: none;
        text-transform: uppercase;
        text-align: center;
        color: white;
        justify-self: center;
        cursor: pointer;
        appearance: none;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; 
        font-size: clamp(0.75rem, 0.75rem + 0vw, 0.75rem);
        line-height: 1.15;
        font-weight: 400;
    }
    .sections section.contact .form form button:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: white;
        pointer-events: none;
        user-select: none;
    }    
</style>