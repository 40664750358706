<template>
    <div class="grid">
        <div class="grid_item"></div>
        <div class="grid_item"></div>
        <div class="grid_item"></div>
        <div class="grid_item"></div>
        <div class="grid_item"></div>
        <div class="grid_item"></div>
    </div>
</template>

<script>    
    export default {
        name: 'PageGrid',
        
        // Properties returned from data() become reactive state
        // and will be exposed on `this`.
        data() {
            return {}
        },
        
        props: {},
        
        // Methods are functions that mutate state and trigger updates.
        // They can be bound as event listeners in templates.
        methods: {},
        
        // Lifecycle hooks are called at different stages
        // of a component's lifecycle.
        // This function will be called when the component is mounted.
        mounted() {}
        
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .grid {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 1px;
        z-index: 100;
        pointer-events: none;
        user-select: none;
        display: none;
    }
    @media (min-width: 768px) {
        .grid {
            right: 30%;
        }
    }
    .grid .grid_item {
        background-color: rgba(105, 105, 105, 0.5)
    }
</style>