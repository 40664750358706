<template>
    <div>        
        <div class="rows" ref="rows" v-bind:class="{ show: store.introAnimationDone && pageTransitionDone }" v-if="store.appData.rows">
			
			<div class="row" v-for="row in store.appData.rows" :key="row">

                <ul class="items" v-if="row.variant == 'variant_one'">
					<li class="item" v-for="(item, index) in row.items" :key="item" v-bind:data-start="index == 0 ? 1 : 4" v-bind:data-width="index == 0 ? 2 : 2" v-bind:id="item.id" v-bind:data-index="index">
						<a v-bind:href="'/cases/' + item.id" v-bind:data-largeimage="item.image_sizes.large.url"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = item.service_type, client = item.service_client"
                           @mouseleave="hover = false">
							<picture>
                                <img class="lazy" loading="lazy" decoding="async" v-bind:style="{ 'aspect-ratio': item.image_sizes.medium.width / item.image_sizes.medium.height }" v-bind:data-src="item.image_sizes.medium.url" v-bind:width="item.image_sizes.medium.width" v-bind:height="item.image_sizes.medium.height">
                            </picture>
                        </a>					
					</li>
                </ul>				
				<!--<ul class="items" v-if="row.variant == 'variant_one'">
					<li class="item" data-start="1" data-width="2" v-bind:id="row.first_item_project">
                        <a v-bind:href="'/cases/' + row.first_item_project" v-bind:data-largeimage="row.first_item_image.sizes.large"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = row.first_item_project_service_type, client = row.first_item_project_service_client"
                           @mouseleave="hover = false">
							<picture>
                                <img class="lazy" loading="lazy" decoding="async" v-bind:style="{ 'aspect-ratio': row.first_item_image.width / row.first_item_image.height }" v-bind:data-src="row.first_item_image.sizes.medium" v-bind:width="row.first_item_image.width" v-bind:height="row.first_item_image.height">
                            </picture>
                        </a>
                    </li>
                    <li class="item" data-start="4" data-width="2" v-bind:id="row.second_item_project">
                        <a v-bind:href="'/cases/' + row.second_item_project" v-bind:data-largeimage="row.second_item_image.sizes.large"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = row.second_item_project_service_type, client = row.second_item_project_service_client"
                           @mouseleave="hover = false">
							<picture>
                                <img class="lazy" loading="lazy" decoding="async" v-bind:style="{ 'aspect-ratio': row.second_item_image.width / row.second_item_image.height }" v-bind:data-src="row.second_item_image.sizes.medium" v-bind:width="row.second_item_image.width" v-bind:height="row.second_item_image.height">
                            </picture>
                        </a>
                    </li>
                </ul>-->

                <ul class="items" v-else-if="row.variant == 'variant_two'">
					<li class="item" v-for="(item, index) in row.items" :key="item" v-bind:data-start="index == 0 ? 4 : 4" v-bind:data-width="index == 0 ? 3 : 3" v-bind:id="item.id" v-bind:data-index="index">
						<a v-bind:href="'/cases/' + item.id" v-bind:data-largeimage="item.image_sizes.large.url"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = item.service_type, client = item.service_client"
                           @mouseleave="hover = false">
							<picture>
                                <img class="lazy" loading="lazy" decoding="async" v-bind:style="{ 'aspect-ratio': item.image_sizes.medium.width / item.image_sizes.medium.height }" v-bind:data-src="item.image_sizes.medium.url" v-bind:width="item.image_sizes.medium.width" v-bind:height="item.image_sizes.medium.height">
                            </picture>
                        </a>					
					</li>
                </ul>				
                <!--<ul class="items" v-else-if="row.variant == 'variant_two'">
                    <li class="item" data-start="4" data-width="3" v-bind:id="row.first_item_project">
                        <a v-bind:href="'/cases/' + row.first_item_project" v-bind:data-largeimage="row.first_item_image.sizes.large"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = row.first_item_project_service_type, client = row.first_item_project_service_client"
                           @mouseleave="hover = false">
							<picture>
                                <img class="lazy" loading="lazy" decoding="async" v-bind:style="{ 'aspect-ratio': row.first_item_image.width / row.first_item_image.height }" v-bind:data-src="row.first_item_image.sizes.medium" v-bind:width="row.first_item_image.width" v-bind:height="row.first_item_image.height">
                            </picture>
                        </a>
                    </li> 
                </ul>-->
				
				<ul class="items" v-else-if="row.variant == 'variant_three'" data-variant="one">
					<li class="item" v-for="(item, index) in row.items" :key="item" v-bind:data-start="index == 0 ? 1 : 5" v-bind:data-width="index == 0 ? 3 : 2" v-bind:id="item.id" v-bind:data-index="index">
						<a v-bind:href="'/cases/' + item.id" v-bind:data-largeimage="item.image_sizes.large.url"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = item.service_type, client = item.service_client"
                           @mouseleave="hover = false">
							<picture>
                                <img class="lazy" loading="lazy" decoding="async" v-bind:style="{ 'aspect-ratio': item.image_sizes.medium.width / item.image_sizes.medium.height }" v-bind:data-src="item.image_sizes.medium.url" v-bind:width="item.image_sizes.medium.width" v-bind:height="item.image_sizes.medium.height">
                            </picture>
                        </a>					
					</li>
                </ul>
                <!--<ul class="items" v-else-if="row.variant == 'variant_three'" data-variant="one">
                    <li class="item" data-start="1" data-width="3" v-bind:id="row.first_item_project">
                        <a v-bind:href="'/cases/' + row.first_item_project" v-bind:data-largeimage="row.first_item_image.sizes.large"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = row.first_item_project_service_type, client = row.first_item_project_service_client"
                           @mouseleave="hover = false">
							<picture>
                                <img class="lazy" loading="lazy" decoding="async" v-bind:style="{ 'aspect-ratio': row.first_item_image.width / row.first_item_image.height }" v-bind:data-src="row.first_item_image.sizes.medium" v-bind:width="row.first_item_image.width" v-bind:height="row.first_item_image.height">
                            </picture>
                        </a>
                    </li>
                    <li class="item" data-start="5" data-width="2" v-bind:id="row.second_item_project">
                        <a v-bind:href="'/cases/' + row.second_item_project" v-bind:data-largeimage="row.second_item_image.sizes.large"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = row.second_item_project_service_type, client = row.second_item_project_service_client"
                           @mouseleave="hover = false">
							<picture>
                                <img class="lazy" loading="lazy" decoding="async" v-bind:style="{ 'aspect-ratio': row.second_item_image.width / row.second_item_image.height }" v-bind:data-src="row.second_item_image.sizes.medium" v-bind:width="row.second_item_image.width" v-bind:height="row.second_item_image.height">
                            </picture>
                        </a>
                    </li>
                </ul>-->
				
				<ul class="items" v-else-if="row.variant == 'variant_four'" data-variant="two">
					<li class="item" v-for="(item, index) in row.items" :key="item" v-bind:data-start="index == 0 ? 1 : 4" v-bind:data-width="index == 0 ? 2 : 3" v-bind:id="item.id" v-bind:data-index="index">
						<a v-bind:href="'/cases/' + item.id" v-bind:data-largeimage="item.image_sizes.large.url"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = item.service_type, client = item.service_client"
                           @mouseleave="hover = false">
							<picture>
                                <img class="lazy" loading="lazy" decoding="async" v-bind:style="{ 'aspect-ratio': item.image_sizes.medium.width / item.image_sizes.medium.height }" v-bind:data-src="item.image_sizes.medium.url" v-bind:width="item.image_sizes.medium.width" v-bind:height="item.image_sizes.medium.height">
                            </picture>
                        </a>					
					</li>
                </ul>
                <!--<ul class="items" v-else-if="row.variant == 'variant_four'" data-variant="two">
                    <li class="item" data-start="1" data-width="2" v-bind:id="row.first_item_project">
                        <a v-bind:href="'/cases/' + row.first_item_project" v-bind:data-largeimage="row.first_item_image.sizes.large"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = row.first_item_project_service_type, client = row.first_item_project_service_client"
                           @mouseleave="hover = false">
							<picture>
                                <img class="lazy" loading="lazy" decoding="async" v-bind:style="{ 'aspect-ratio': row.first_item_image.width / row.first_item_image.height }" v-bind:data-src="row.first_item_image.sizes.medium" v-bind:width="row.first_item_image.width" v-bind:height="row.first_item_image.height">
                            </picture>
                        </a>
                    </li>
                    <li class="item" data-start="4" data-width="3" v-bind:id="row.second_item_project">
                        <a v-bind:href="'/cases/' + row.second_item_project" v-bind:data-largeimage="row.second_item_image.sizes.large"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = row.second_item_project_service_type, client = row.second_item_project_service_client"
                           @mouseleave="hover = false">
							<picture>
                                <img class="lazy" loading="lazy" decoding="async" v-bind:style="{ 'aspect-ratio': row.second_item_image.width / row.second_item_image.height }" v-bind:data-src="row.second_item_image.sizes.medium" v-bind:width="row.second_item_image.width" v-bind:height="row.second_item_image.height">
                            </picture>
                        </a>
                    </li>
                </ul>-->
				
				<ul class="items" v-else-if="row.variant == 'variant_five'" data-variant="one">
					<li class="item" v-for="(item, index) in row.items" :key="item" v-bind:data-start="index == 0 ? 2 : 5" v-bind:data-width="index == 0 ? 2 : 2" v-bind:id="item.id" v-bind:data-index="index">
						<a v-bind:href="'/cases/' + item.id" v-bind:data-largeimage="item.image_sizes.large.url"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = item.service_type, client = item.service_client"
                           @mouseleave="hover = false">
							<picture>
                                <img class="lazy" loading="lazy" decoding="async" v-bind:style="{ 'aspect-ratio': item.image_sizes.medium.width / item.image_sizes.medium.height }" v-bind:data-src="item.image_sizes.medium.url" v-bind:width="item.image_sizes.medium.width" v-bind:height="item.image_sizes.medium.height">
                            </picture>
                        </a>					
					</li>
                </ul>
                <!--<ul class="items" v-else-if="row.variant == 'variant_five'" data-variant="one">
                    <li class="item" data-start="2" data-width="2" v-bind:id="row.first_item_project">
                        <a v-bind:href="'/cases/' + row.first_item_project" v-bind:data-largeimage="row.first_item_image.sizes.large"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = row.first_item_project_service_type, client = row.first_item_project_service_client"
                           @mouseleave="hover = false">
							<picture>
                                <img class="lazy" loading="lazy" decoding="async" v-bind:style="{ 'aspect-ratio': row.first_item_image.width / row.first_item_image.height }" v-bind:data-src="row.first_item_image.sizes.medium" v-bind:width="row.first_item_image.width" v-bind:height="row.first_item_image.height">
                            </picture>
                        </a>
                    </li>
                    <li class="item" data-start="5" data-width="2" v-bind:id="row.second_item_project">
                        <a v-bind:href="'/cases/' + row.second_item_project" v-bind:data-largeimage="row.second_item_image.sizes.large"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = row.second_item_project_service_type, client = row.second_item_project_service_client"
                           @mouseleave="hover = false">
							<picture>
                                <img class="lazy" loading="lazy" decoding="async" v-bind:style="{ 'aspect-ratio': row.second_item_image.width / row.second_item_image.height }" v-bind:data-src="row.second_item_image.sizes.medium" v-bind:width="row.second_item_image.width" v-bind:height="row.second_item_image.height">
                            </picture>
                        </a>
                    </li>
                </ul>-->
				
				<ul class="items" v-else-if="row.variant == 'variant_six'">
					<li class="item" v-for="(item, index) in row.items" :key="item" v-bind:data-start="index == 0 ? 2 : 2" v-bind:data-width="index == 0 ? 3 : 3" v-bind:id="item.id" v-bind:data-index="index">
						<a v-bind:href="'/cases/' + item.id" v-bind:data-largeimage="item.image_sizes.large.url"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = item.service_type, client = item.service_client"
                           @mouseleave="hover = false">
							<picture>
                                <img class="lazy" loading="lazy" decoding="async" v-bind:style="{ 'aspect-ratio': item.image_sizes.medium.width / item.image_sizes.medium.height }" v-bind:data-src="item.image_sizes.medium.url" v-bind:width="item.image_sizes.medium.width" v-bind:height="item.image_sizes.medium.height">
                            </picture>
                        </a>					
					</li>
                </ul>
                <!--<ul class="items" v-else-if="row.variant == 'variant_six'">
                    <li class="item" data-start="2" data-width="3" v-bind:id="row.first_item_project">
                        <a v-bind:href="'/cases/' + row.first_item_project" v-bind:data-largeimage="row.first_item_image.sizes.large"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = row.first_item_project_service_type, client = row.first_item_project_service_client"
                           @mouseleave="hover = false">
							<picture>
                                <img class="lazy" loading="lazy" decoding="async" v-bind:style="{ 'aspect-ratio': row.first_item_image.width / row.first_item_image.height }" v-bind:data-src="row.first_item_image.sizes.medium" v-bind:width="row.first_item_image.width" v-bind:height="row.first_item_image.height">
                            </picture>
                        </a>
                    </li>
                </ul>-->
				
            </div>
			
            <!--
			<div class="row">
                <ul class="items">
                    <li class="item" data-start="1" data-width="2" id="1">
                        <a href="/cases/1" data-largeimage="https://blankcreative.info/placeholders/project-01.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Conceptual design', client = 'A performance fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <source srcset="https://blankcreative.info/placeholders/project-01.jpg 1x" media="(min-width: 768px)" width="666" height="888">
                                <source srcset="https://blankcreative.info/placeholders/project-01.jpg 1x, https://blankcreative.info/placeholders/project-01.jpg 2x" media="(min-width: 768px)" width="666" height="888">
                                <img src="https://blankcreative.info/placeholders/project-01.jpg" alt="A description of the image." srcset="https://blankcreative.info/placeholders/project-01.jpg 1x, https://blankcreative.info/placeholders/project-01.jpg 2x, https://blankcreative.info/placeholders/project-01.jpg 3x" loading="lazy" decoding="async" width="666" height="888">
                            </picture>
                        </a>
                    </li>
                    <li class="item" data-start="4" data-width="2" id="2">
                        <a href="/cases/2" data-largeimage="https://blankcreative.info/placeholders/project-02.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Shoot direction', client = 'A luxury fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img class="lazy" src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 666 888'%3E%3C/svg%3E" data-src="https://blankcreative.info/placeholders/project-02.jpg" loading="lazy" decoding="async" width="666" height="888">
                            </picture>
                        </a>
                    </li>
                </ul>
            </div>
			
			<div class="row">
                <ul class="items">
                    <li class="item" data-start="1" data-width="2" id="1">
                        <a href="/cases/1" data-largeimage="https://blankcreative.info/placeholders/project-01.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Conceptual design', client = 'A performance fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <source srcset="https://blankcreative.info/placeholders/project-01.jpg 1x" media="(min-width: 768px)" width="666" height="888">
                                <source srcset="https://blankcreative.info/placeholders/project-01.jpg 1x, https://blankcreative.info/placeholders/project-01.jpg 2x" media="(min-width: 768px)" width="666" height="888">
                                <img src="https://blankcreative.info/placeholders/project-01.jpg" alt="A description of the image." srcset="https://blankcreative.info/placeholders/project-01.jpg 1x, https://blankcreative.info/placeholders/project-01.jpg 2x, https://blankcreative.info/placeholders/project-01.jpg 3x" loading="lazy" decoding="async" width="666" height="888">
                            </picture>
                        </a>
                    </li>
                    <li class="item" data-start="4" data-width="2" id="2">
                        <a href="/cases/2" data-largeimage="https://blankcreative.info/placeholders/project-02.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Shoot direction', client = 'A luxury fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img class="lazy" src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 666 888'%3E%3C/svg%3E" data-src="https://blankcreative.info/placeholders/project-02.jpg" loading="lazy" decoding="async" width="666" height="888">
                            </picture>
                        </a>
                    </li>
                </ul>
            </div>
			
            <div class="row">
                <ul class="items">
                    <li class="item" data-start="4" data-width="3" id="3">
                        <a href="/cases/3" data-largeimage="https://blankcreative.info/placeholders/project-03.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Premium design', client = 'A sustainable startup tailor brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img class="lazy" src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 998 1334'%3E%3C/svg%3E" data-src="https://blankcreative.info/placeholders/project-03.jpg" loading="lazy" decoding="async" width="998" height="1334">
                            </picture>
                        </a>
                    </li> 
                </ul>
            </div>
            <div class="row">
                <ul class="items" data-variant="one">
                    <li class="item" data-start="1" data-width="3" id="4">
                        <a href="/cases/4" data-largeimage="https://blankcreative.info/placeholders/project-04.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Creative direction', client = 'A legacy department store'"
                           @mouseleave="hover = false">
                            <picture>
                                <img class="lazy" src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 998 1334'%3E%3C/svg%3E" data-src="https://blankcreative.info/placeholders/project-04.jpg" loading="lazy" decoding="async" width="998" height="1334">
                            </picture>
                        </a>
                    </li>
                    <li class="item" data-start="5" data-width="2" id="5">
                        <a href="/cases/5" data-largeimage="https://blankcreative.info/placeholders/project-05.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Conceptual design', client = 'A premium jeans brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img class="lazy" src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 666 888'%3E%3C/svg%3E" data-src="https://blankcreative.info/placeholders/project-05.jpg" loading="lazy" decoding="async" width="666" height="888">
                            </picture>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="row">
                <ul class="items">
                    <li class="item" data-start="1" data-width="2" id="6">
                        <a href="/cases/6" data-largeimage="https://blankcreative.info/placeholders/project-06.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Fashion design', client = 'A niche fashion boutique'"
                           @mouseleave="hover = false">
                            <picture>
                                <img class="lazy" src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 666 886'%3E%3C/svg%3E" data-src="https://blankcreative.info/placeholders/project-06.jpg" loading="lazy" decoding="async" width="666" height="886">
                            </picture>
                        </a>
                    </li>
                    <li class="item" data-start="4" data-width="2" id="7">
                        <a href="/cases/7" data-largeimage="https://blankcreative.info/placeholders/project-07.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Shoot production', client = 'A performance fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img class="lazy" src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 666 888'%3E%3C/svg%3E" data-src="https://blankcreative.info/placeholders/project-07.jpg" loading="lazy" decoding="async" width="666" height="888">
                            </picture>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="row">
                <ul class="items" data-variant="two">
                    <li class="item" data-start="1" data-width="2" id="8">
                        <a href="/cases/8" data-largeimage="https://blankcreative.info/placeholders/project-08.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Shoot direction', client = 'A premium jeans brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img class="lazy" src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 666 888'%3E%3C/svg%3E" data-src="https://blankcreative.info/placeholders/project-08.jpg" loading="lazy" decoding="async" width="666" height="888">
                            </picture>
                        </a>
                    </li>
                    <li class="item" data-start="4" data-width="3" id="9">
                        <a href="/cases/9" data-largeimage="https://blankcreative.info/placeholders/project-09.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Premium design', client = 'A niche fashion boutique'"
                           @mouseleave="hover = false">
                            <picture>
                                <img class="lazy" src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 998 1334'%3E%3C/svg%3E" data-src="https://blankcreative.info/placeholders/project-09.jpg" loading="lazy" decoding="async" width="998" height="1334">
                            </picture>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="row">
                <ul class="items" data-variant="one">
                    <li class="item" data-start="2" data-width="2" id="10">
                        <a href="/cases/10" data-largeimage="https://blankcreative.info/placeholders/project-10.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Conceptual design', client = 'A performance fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img class="lazy" src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 666 888'%3E%3C/svg%3E" data-src="https://blankcreative.info/placeholders/project-10.jpg" loading="lazy" decoding="async" width="666" height="888">
                            </picture>
                        </a>
                    </li>
                    <li class="item" data-start="5" data-width="2" id="11">
                        <a href="/cases/11" data-largeimage="https://blankcreative.info/placeholders/project-11.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Conceptual design', client = 'A performance fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img class="lazy" src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 666 888'%3E%3C/svg%3E" data-src="https://blankcreative.info/placeholders/project-11.jpg" loading="lazy" decoding="async" width="666" height="888">
                            </picture>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="row">
                <ul class="items">
                    <li class="item" data-start="2" data-width="3" id="12">
                        <a href="/cases/12" data-largeimage="https://blankcreative.info/placeholders/project-12.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Conceptual design', client = 'A performance fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img src="https://blankcreative.info/placeholders/project-12.jpg" loading="lazy" decoding="async" width="998" height="1334">
                            </picture>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="row">
                <ul class="items">
                    <li class="item" data-start="1" data-width="2" id="13">
                        <a href="/cases/13" data-largeimage="https://blankcreative.info/placeholders/project-13.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Conceptual design', client = 'A performance fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img src="https://blankcreative.info/placeholders/project-13.jpg" loading="lazy" decoding="async" width="666" height="888">
                            </picture>
                        </a>
                    </li>
                    <li class="item" data-start="4" data-width="2" id="14">
                        <a href="/cases/14" data-largeimage="https://blankcreative.info/placeholders/project-14.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Conceptual design', client = 'A performance fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img src="https://blankcreative.info/placeholders/project-14.jpg" loading="lazy" decoding="async" width="666" height="888">
                            </picture>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="row">
                <ul class="items">
                    <li class="item" data-start="4" data-width="3" id="15">
                        <a href="/cases/15" data-largeimage="https://blankcreative.info/placeholders/project-15.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Conceptual design', client = 'A performance fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img src="https://blankcreative.info/placeholders/project-15.jpg" loading="lazy" decoding="async" width="998" height="1334">
                            </picture>
                        </a>
                    </li>
                </ul>
            </div>            
            <div class="row">
                <ul class="items" data-variant="one">
                    <li class="item" data-start="1" data-width="3" id="16">
                        <a href="/cases/16" data-largeimage="https://blankcreative.info/placeholders/project-16.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Conceptual design', client = 'A performance fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img src="https://blankcreative.info/placeholders/project-16.jpg" loading="lazy" decoding="async" width="998" height="1334">
                            </picture>
                        </a>
                    </li>
                    <li class="item" data-start="5" data-width="2" id="17">
                        <a href="/cases/17" data-largeimage="https://blankcreative.info/placeholders/project-17.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Conceptual design', client = 'A performance fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img src="https://blankcreative.info/placeholders/project-17.jpg" loading="lazy" decoding="async" width="666" height="888">
                            </picture>
                        </a>
                    </li>
                </ul>
            </div>        
            <div class="row">
                <ul class="items">
                    <li class="item" data-start="1" data-width="2" id="18">
                        <a href="/cases/18" data-largeimage="https://blankcreative.info/placeholders/project-18.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Conceptual design', client = 'A performance fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img src="https://blankcreative.info/placeholders/project-18.jpg" loading="lazy" decoding="async" width="666" height="888">
                            </picture>
                        </a>
                    </li>
                    <li class="item" data-start="4" data-width="2" id="19">
                        <a href="/cases/19" data-largeimage="https://blankcreative.info/placeholders/project-19.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Conceptual design', client = 'A performance fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img src="https://blankcreative.info/placeholders/project-19.jpg" loading="lazy" decoding="async" width="666" height="888">
                            </picture>
                        </a>
                    </li>
                </ul>
            </div>       
            <div class="row">
                <ul class="items" data-variant="three">
                    <li class="item" data-start="1" data-width="2" id="20">
                        <a href="/cases/20" data-largeimage="https://blankcreative.info/placeholders/project-20.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Conceptual design', client = 'A performance fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img src="https://blankcreative.info/placeholders/project-20.jpg" loading="lazy" decoding="async" width="666" height="888">
                            </picture>
                        </a>
                    </li>
                    <li class="item" data-start="4" data-width="3" id="21">
                        <a href="/cases/21" data-largeimage="https://blankcreative.info/placeholders/project-21.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Conceptual design', client = 'A performance fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img src="https://blankcreative.info/placeholders/project-21.jpg" loading="lazy" decoding="async" width="998" height="1334">
                            </picture>
                        </a>
                    </li>
                </ul>
            </div>      
            <div class="row">
                <ul class="items" data-variant="one">
                    <li class="item" data-start="2" data-width="2" id="22">
                        <a href="/cases/22" data-largeimage="https://blankcreative.info/placeholders/project-22.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Conceptual design', client = 'A performance fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img src="https://blankcreative.info/placeholders/project-22.jpg" loading="lazy" decoding="async" width="666" height="888">
                            </picture>
                        </a>
                    </li>
                    <li class="item" data-start="5" data-width="2" id="23">
                        <a href="/cases/23" data-largeimage="https://blankcreative.info/placeholders/project-23.jpg"
                           @click.prevent="goToCase"
                           @mouseover="hover = true, service = 'Conceptual design', client = 'A performance fashion brand'"
                           @mouseleave="hover = false">
                            <picture>
                                <img src="https://blankcreative.info/placeholders/project-23.jpg" loading="lazy" decoding="async" width="666" height="888">
                            </picture>
                        </a>
                    </li>
                </ul>
            </div>
		-->
			
        </div>
        
        <div class="services" ref="services" v-bind:class="{ show: hover && store.introAnimationDone && pageTransitionDone }">
            <div class="type"><div>{{ service }}</div></div>
            <div class="for"><div>for</div></div>
            <div class="client"><div>{{ client }}</div></div>
        </div>
		
    </div>
</template>

<script>
    import LazyLoad from 'vanilla-lazyload';
    import gsap from 'gsap'
    import { store } from '../store.js'

    export default {
        
        components: {},
        
        data() {
            return {
                store,
                hover: false,
                service: null,
                client: null,
                href: null,
                pageTransitionDone: false
            }
        },
        created() {},      
        computed: {}, 
        methods: {
            setPageTransitionDone() {
                console.log('setPageTransitionDone');
                this.pageTransitionDone = true
            },
            resetPageTransitionDone() {
                console.log('resetPageTransitionDone');
                this.pageTransitionDone = false
            },
            clearCanvas(){
                store.ctx.clearRect(0, 0, store.windowWidth, store.windowHeight);
            },
            drawCanvas(){
				console.log('drawCanvas', store.image.img, store.image.newLeft, store.image.newTop, store.image.newWidth, store.image.newHeight);
                store.ctx.clearRect(0, 0, store.windowWidth, store.windowHeight);
                store.ctx.drawImage(store.image.img, store.image.newLeft, store.image.newTop, store.image.newWidth, store.image.newHeight);
            },
            goToCase(element){
                store.homeScrollY = window.scrollY;
                const element_target = element.target;
                const link = element_target.closest('a');
                if( link ){
                    const href = link.getAttribute('href');
                    const largeimage = link.dataset.largeimage;
                    if( href && href != '' && largeimage ){
                        
                        this.href = href;
                        
                        var downloadingImage = new Image();
                        downloadingImage.onload = () => {
                            
                            store.image.img = downloadingImage;
                            const link_rect = link.getBoundingClientRect();
                            store.image.left = Math.round(link_rect.left);
                            store.image.top = Math.round(link_rect.top);
                            store.image.width = link_rect.width;
                            store.image.height = link_rect.height;
                            store.image.newLeft = Math.round(link_rect.left);
                            store.image.newTop = Math.round(link_rect.top);
                            store.image.newWidth = link_rect.width;
                            store.image.newHeight = link_rect.height;
                            
                            this.drawCanvas();
                            
                            link.style.opacity = '0'
                            
                            this.resetPageTransitionDone()
                            
                            let width = window.innerWidth
                            let height = (store.image.height / store.image.width) * window.innerWidth
                            
                            if( window.innerWidth >= 768 ){
                                width = (store.image.width / store.image.height) * window.innerHeight
                                height = window.innerHeight
                            }
                            
                            gsap.to(store.image, {
                                duration: 0.75,
                                delay: 0.25,
                                ease: "expo",
                                newLeft: 0, 
                                newTop: 0, 
                                newWidth: width, 
                                newHeight: height, 
                                onUpdate: () => {
                                    this.drawCanvas()
                                },
                                onComplete: () => {
                                    this.$router.push(href),
                                    setTimeout( () => {
                                        this.clearCanvas()
                                    }, 550)
                                }
                            });
                            
                        };
                        downloadingImage.src = largeimage;
                        
                    }                   
                }
            },
            onBeforeEnter(el) {
                gsap.set(el, {
                    opacity: 0
                })
            },
            onEnter(el, done) {
                gsap.to(el, {
                    duration: 0.5,
                    opacity: 1,
                    onComplete: done
                })
            },
            onLeave(el, done){
                gsap.to(el, {
                    duration: 2.5,
                    opacity: 0,
                    onComplete: done
                })
            },
            onBeforeEnterFade(el) {
                gsap.set(el, {
                    opacity: 0
                })
            },
            onEnterFade(el, done) {
                gsap.to(el, {
                    duration: 0.25,
                    opacity: 1,
                    onComplete: done
                })
            },
            onLeaveFade(el, done){
                gsap.to(el, {
                    duration: 0.25,
                    opacity: 0,
                    onComplete: done
                })
            },
            initVariant(element){
                const variant = element.dataset.variant
                if(variant == 'one'){
                    const second_item = element.querySelector('li:nth-child(2)');
					const margin_top = -(second_item.clientHeight - (second_item.querySelector('img').clientHeight * 0.5)) + 'px';
                    second_item.style.marginTop = margin_top;
                }
                if(variant == 'two'){
                    const first_item = element.querySelector('li:nth-child(1)');
                    const second_item = element.querySelector('li:nth-child(2)');
					const margin_top = -(first_item.clientHeight - (first_item.querySelector('img').clientHeight * 0.5)) + 'px';
                    second_item.style.marginTop = margin_top;
                }
                if(variant == 'three'){
                    const first_item = element.querySelector('li:nth-child(1)');
                    const second_item = element.querySelector('li:nth-child(2)');
					const margin_top = -(first_item.clientHeight - (first_item.querySelector('img').clientHeight * 0.25)) + 'px';
                    second_item.style.marginTop = margin_top;
                }
                
            },
            positionItem(element){
                const variant = element.dataset.variant
                switch (variant) {
                    case 'up':
                        // margin-top: calc(((((100vw * 0.7 - 5px) /6 * 2) + 1px) / 666 * 888) * 0.5);
                        element.style.marginBottom = (element.clientHeight - (element.querySelector('img').clientHeight * 0.5)) + 'px'
                        break;
                    case 'one':
                        element.style.marginTop = -(element.clientHeight - (element.querySelector('img').clientHeight * 0.5)) + 'px'
                        break;
                }
            }

        },
        mounted() {
            
            const items = this.$refs.rows.querySelectorAll('.items[data-variant]');
            items.forEach(this.initVariant)
            
            const scrollY = store.homeScrollY;
            const caseHandle = store.caseHandle;
            console.log('scrollY, caseHandle', scrollY, caseHandle);
            if( scrollY && scrollY != 0 ){
                console.log('scroll to element');
                document.documentElement.scrollTop = scrollY;
                store.homeScrollY = 0
                this.setPageTransitionDone()
            } else if( caseHandle && caseHandle != null ){
                console.log('scroll to case');
                const target = document.getElementById(caseHandle)
                if( target ){
                    
                    // scrollIntoView and align to top
                    target.scrollIntoView(true)
                    
                    const targetImg = target.querySelector('img')
                    
                    if( targetImg && store.image.img && store.image.img != null){
                        
                        console.log(store.image.img, store.image.newLeft, store.image.newTop, store.image.newWidth, store.image.newHeight);
                        
                        var downloadingImage = new Image();
                        downloadingImage.onload = () => {
                            
                            store.image.img = downloadingImage;
                            const link_rect = targetImg.getBoundingClientRect();
							console.log('targetImg', targetImg);
                            
                            this.drawCanvas()
                            
                            gsap.to(store.image, {
                                duration: 0.75,
                                ease: "expo",
                                newLeft: Math.round(link_rect.left), 
                                newTop: Math.round(link_rect.top), 
                                newWidth: Math.round(link_rect.width), 
                                newHeight: Math.round(link_rect.height), 
                                onUpdate: () => {
                                    this.drawCanvas()
                                },
                                onComplete: () => {
                                    this.setPageTransitionDone()
                                    setTimeout( () => {
                                        this.clearCanvas()
                                    }, 250)
                                }
                            });
                            
                        };
                        downloadingImage.src = store.image.img.src;
                        
                    }
                    
                }
                store.caseHandle = null
            } else {
                console.log('scroll to top');
                document.documentElement.scrollTop = 0;
                this.setPageTransitionDone()
            }
            
            let lazyLoadInstance = new LazyLoad();
            console.log(lazyLoadInstance);
            
        },
        unmounted() {}
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .rows {
        display: grid;
        grid-auto-flow: row;
        grid-row-gap: 100px;
        pointer-events: none;
        user-select: none;
        opacity: 0;
        transition: opacity 250ms ease;
    }
    @media (min-width: 768px) {
        .rows {
            margin-right: 30%;
            grid-row-gap: 200px;
        }
    }
    .rows.show {
        pointer-events: auto;
        user-select: auto;
        opacity: 1;
        transition: opacity 250ms ease;
    }
    ul.items {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 1px;
    }
    ul.items li {
        display: flex;
		align-items: flex-start;
    }
    ul.items li.item[data-start="1"] {
        grid-column-start: 1
    }
    ul.items li.item[data-start="2"] {
        grid-column-start: 2
    }
    ul.items li.item[data-start="3"] {
        grid-column-start: 3
    }
    ul.items li.item[data-start="4"] {
        grid-column-start: 4
    }
    ul.items li.item[data-start="5"] {
        grid-column-start: 5
    }
    ul.items li.item[data-width="1"] {
        grid-column-end: span 1
    }
    ul.items li.item[data-width="2"] {
        grid-column-end: span 2
    }
    ul.items li.item[data-width="3"] {
        grid-column-end: span 3
    }
    ul.items[data-variant="one"] li:nth-child(2) {
        grid-row: 2;
    }
    ul.items[data-variant="two"] li:nth-child(1) {
        grid-row: 1;
    }
    ul.items[data-variant="two"] li:nth-child(2) {
        grid-row: 2;
    }
    ul.items[data-variant="three"] li:nth-child(1) {
        grid-row: 1;
    }
    ul.items[data-variant="three"] li:nth-child(2) {
        grid-row: 2;
    }
    ul.items li.item a {
        display: block;
		flex: 1;
    }
    ul.items li.item a picture {
        position: relative;
    }
    ul.items li.item a picture img {
        position: relative;
        width: 100%;
        height: auto;
        display: block;
    }
    
    #canvas {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        pointer-events: none;
        user-select: none;
    }
    
    .services {
        position: fixed;
        top: 100px;
        right: auto;
        left: 0;
        width: 100%;
        z-index: 9;
        pointer-events: none;
        user-select: none;
        text-transform: uppercase;
        display: none;
    }
    @media (min-width: 768px) {
        .services {
            top: 180px;
            right: 0;
            left: auto;
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            grid-row-gap: 40px;
        }
    }
    @media (min-width: 768px) {
        .services > div {
            position: relative;
			width: 100%;
            height: 15px;
            line-height: 15px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
			text-align: center;
        }
    }
    @media (min-width: 768px) {
        .services > div > div {
            position: relative;
            opacity: 0;
            transform: translate3d(0, 100%, 0);
            transition: opacity 100ms ease, transform 100ms ease;
            transition-delay: 0ms;
        }
    }
    @media (min-width: 768px) {
        .services.show > div > div {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition: opacity 250ms ease, transform 250ms cubic-bezier(0,0.7,0,1);
        }
    }
    @media (min-width: 768px) {
        .services.show > div:nth-child(1) > div {
            transition-delay: 0ms;
        }
    }
    @media (min-width: 768px) {
        .services.show > div:nth-child(2) > div {
            transition-delay: 50ms;
        }
    }
    @media (min-width: 768px) {
        .services.show > div:nth-child(3) > div {
            transition-delay: 100ms;
        }
    }
    
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 250ms ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
    
    /* lazyload */
    img.lazy {
        opacity: 0;
    }
    img:not(.initial) {
        transition: opacity 1s;
    }
    img.initial,
    img.loaded,
    img.error {
        opacity: 1;
    }

    img:not([src]) {
        visibility: hidden;
    }
</style>