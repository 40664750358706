import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/PageHome.vue";
import About from "@/views/PageAbout.vue";
import Case from "@/views/PageCase.vue";
//import CaseLarge from "@/views/PageCaseLarge.vue";
import NotFound from "@/views/PageNotFound.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/about",
        name: "About",
        component: About
    },
    {
        path: "/cases/:handle",
        name: 'Case',
        component: Case
    },
    /*{
        path: "/cases/:handle/large",
        name: 'CaseLarge',
        component: CaseLarge
    },*/
    {
        //path: "/:notFound",
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: NotFound
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: 'current',
    scrollBehavior(to, from, savedPosition) {
        if ('scrollRestoration' in history) { 
            history.scrollRestoration = 'manual'; 
        }
        if(from.name == "Case" && to.name == 'Home' && savedPosition){
            return savedPosition;
        }
    }
});

router.beforeEach((to, from) => {
    console.log('beforeEach window.scrollY:', window.scrollY)
    console.log('beforeEach from: ', from.name)
    console.log('beforeEach to: ', to.name)
    
    if(from.name == 'Case' && to.name == 'Home'){
        console.log('coming from Case page', from.params.handle)
        const target = document.getElementById(from.params.handle);
        if( target ){
            console.log('target', target)
        }
    }
})

export default router;