<template>
    <div class="notfound">
        <div class="inner">
            <h1>Page not found</h1>
            <ul>
                <li><router-link to="/">Home</router-link></li>
                <li><router-link to="/about">About</router-link></li>
            </ul>
        </div>
    </div>
</template>