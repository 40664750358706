<template>
    <Transition name="fadein" appear>
        <div class="services" ref="services">
            <div class="type" ref="type"></div>
            <div class="for">for</div>
            <div class="client" ref="client"></div>
        </div>
    </Transition>
</template>

<script>
    import { store } from '../store.js'
    import { gsap } from 'gsap';
    
    export default {
        name: 'PageServices',
        
        // Properties returned from data() become reactive state
        // and will be exposed on `this`.
        data() {
            return {
                store,
                services: [],
                serviceIndex: 0,
                servicesMax: 3,
				appData: []
            }
        },		
		created() {},
		computed: {},       
        // Methods are functions that mutate state and trigger updates.
        // They can be bound as event listeners in templates.
        methods: {
			
			addServiceTypeClient(item){
				this.services.push({
					service_type: item.service_type,
					service_client: item.service_client
				})
			},
			
			addServices(){
				const projects = store.appData.projects;
				Object.values(projects).forEach(this.addServiceTypeClient);
				
				/* unique multidimensional array */
				/* https://www.kirupa.com/javascript/removing_duplicate_arrays_from_array.htm */
				let stringArray = this.services.map(JSON.stringify);
				let uniqueStringArray = new Set(stringArray);
				this.services = Array.from(uniqueStringArray, JSON.parse);

				this.shuffleArray(this.services);

				this.initServicesAnimation()
			},
			
            setIntroAnimationDone() {
                console.log('setIntroAnimationDone');
                store.introAnimationDone = true
            },
            resetIntroAnimationDone() {
                store.introAnimationDone = false
            },
            shuffleArray(array) {
                for (let i = array.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    const temp = array[i];
                    array[i] = array[j];
                    array[j] = temp;
                }
            },
            addService(index){
                
                const service = this.services[index]
                const service_type = service.service_type
                const service_client = service.service_client
                
                const typeElement = document.createElement('div')
                typeElement.style.position = 'absolute'
                typeElement.style.textAlign = 'center'
                typeElement.style.top = 0
                typeElement.style.right = 0
                typeElement.style.bottom = 0
                typeElement.style.left = 0
                typeElement.textContent = service_type
                this.$refs.type.appendChild(typeElement);
                
                gsap.set(typeElement, {
                    opacity: 0,
                    yPercent: 100
                })
                
                const clientElement = document.createElement('div')
                clientElement.style.position = 'absolute'
                clientElement.style.textAlign = 'center'
                clientElement.style.top = 0
                clientElement.style.right = 0
                clientElement.style.bottom = 0
                clientElement.style.left = 0
                clientElement.textContent = service_client
                this.$refs.client.appendChild(clientElement);
                
                gsap.set(clientElement, {
                    opacity: 0,
                    yPercent: 100
                })
                
            },
            playServicesAnimation(){
                const existingTypeElement = this.$refs.type.querySelector('div:first-child')
                const existingClientElement = this.$refs.client.querySelector('div:first-child')
                const newTypeElement = this.$refs.type.querySelector('div:last-child')
                const newClientElement = this.$refs.client.querySelector('div:last-child')
                
                // slideout existing type and client
                if(existingTypeElement && existingClientElement){
                    const existing_tl = gsap.timeline();
                    existing_tl.to(existingTypeElement, {
                        duration: 0.25,
                        ease: "power3.out",
                        opacity: 0,
                        yPercent: -100,
                        onComplete: function(){
							if( existingTypeElement.parentNode){
                                existingTypeElement.parentNode.removeChild(existingTypeElement)
                            }      
                        }
                    })
                    existing_tl.to(existingClientElement, {
                        duration: 0.25,
                        ease: "power3.out",
                        opacity: 0,
                        yPercent: -100,
                        onComplete: function(){
							if( existingClientElement.parentNode){
                                existingClientElement.parentNode.removeChild(existingClientElement)
                            }
                        }
                    }, '<0.2')
                }
                if(newTypeElement && newClientElement){
                    const new_tl = gsap.timeline();
                    new_tl.to(newTypeElement, {
                        duration: 0.25,
                        ease: "power3.out",
                        opacity: 1,
                        yPercent: 0
                    })
                    new_tl.to(newClientElement, {
                        duration: 0.25,
                        ease: "power3.out",
                        opacity: 1,
                        yPercent: 0
                    }, '<0.2')
                }
                
                if(this.serviceIndex < this.servicesMax){
                    this.addNextServicesAnimation()
                } else {
                    setTimeout(this.endServicesAnimation, 1000);
                }
            },
            addNextServicesAnimation(){
                
                if(this.serviceIndex < (this.services.length - 1)){
                    this.serviceIndex++;
                } else {
                    this.serviceIndex = 0
                }                
                
                this.addService(this.serviceIndex)
                setTimeout(this.playServicesAnimation, 2000);
                
                
            },
            endServicesAnimation(){
                console.log('ServicesAnimation ended');
                const servicesElement = this.$refs.services
                if(servicesElement){
                    gsap.to(servicesElement, {
                        duration: 0.5,
                        ease: 'expo',
                        opacity: 0,
                        onComplete: this.setIntroAnimationDone
                    })
                }
            },
            startServicesAnimation(){
                // slidein new type and client
                const existingTypeElement = this.$refs.type.querySelector('div:first-child')
                const existingClientElement = this.$refs.client.querySelector('div:first-child')
                if(existingTypeElement && existingClientElement){
                    const existing_tl = gsap.timeline();
                    existing_tl.to(existingTypeElement, {
                        duration: 0.5,
                        ease: 'expo',
                        opacity: 1,
                        yPercent: 0
                    })
                    existing_tl.to(existingClientElement, {
                        duration: 0.5,
                        ease: 'expo',
                        opacity: 1,
                        yPercent: 0
                    }, '<0.2')
                }
                
                this.addNextServicesAnimation()
            },
            initServicesAnimation(){
                
                this.addService(this.serviceIndex)
                this.startServicesAnimation()
                
            }
        },
        
        // Lifecycle hooks are called at different stages
        // of a component's lifecycle.
        // This function will be called when the component is mounted.
        mounted() {
			this.addServices()
		},
        
        unmounted(){}
        
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>    
    .services {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-row-gap: 40px;
        text-align: center;
        pointer-events: none;
        user-select: none;
        text-transform: uppercase;
    }
    .services > div {
        position: relative;
		width: 100%;
        height: 15px;
        line-height: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    .fadein-enter-active,
    .fadein-leave-active {
        transition: opacity 500ms ease;
    }

    .fadein-enter-from,
    .fadein-leave-to {
        opacity: 0;
    }
</style>